import { Box } from 'theme-ui';
import { ThemeProvider } from 'theme-ui';
import { Global } from '@emotion/react';
import Head from 'next/head';

import theme from '../theme';
import Fonts from 'components/fonts';
import Crisp from 'components/crisp';

function Layout({ children }) {
  return (
    <ThemeProvider theme={theme}>
      <Crisp />
      <Head>
        <title>fotokorner :: admin</title>
      </Head>
      <Box
        sx={{
          fontFamily: 'body',
          color: 'white',
        }}
      >
        {children}
      </Box>
      <Fonts />
      <Global
        styles={(theme) => ({
          body: {
            backgroundColor: theme.colors.background,
            margin: 0,
            padding: 0,
          },
        })}
      />
    </ThemeProvider>
  );
}

export default Layout;
