export default {
  breakpoints: ['40em', '52em', '76em'],
  fontSizes: [14, 16, 20, 24, 32, 48, 64],
  colors: {
    brand: '#ff0048',
    focus: '#ff0048',
    background: '#2a292e',
    boxBackground: '#38383d',
    boxBorder: '#3f3f44',
    black: '#000000',
    white: '#ffffff',
    text: {
      light: '#000000',
      dark: '#f8f8f8',
    },
    accent1: '#ffffff',
    accent2: '#a6a6a6',
    navBackground: '#323234',
    navLink: '#a7a7a7',
    navLinkActive: '#ffffff',
    border: '#707070',
  },
  space: [0, 4, 8, 16, 32, 64, 128, 256],
  fonts: {
    body: 'Montserrat',
    heading: 'Coolvetica Rg',
  },
  fontWeights: {
    body: 400,
    heading: 700,
    bold: 700,
  },
  lineHeights: {
    body: 1.5,
    heading: 1.25,
  },
  shadows: {
    small: '0 0 6px 0 rgba(0, 0, 0, 0.16)',
    large: '0 0 99px 0 rgba(0, 0, 0, 0.16)',
  },
  forms: {
    input: {
      bg: '#2a2a2e',
      borderColor: 'border',
      borderStyle: 'solid',
      borderWidth: 1,
      fontSize: 2,
    },
    textarea: {
      bg: '#2a2a2e',
      borderColor: 'border',
      borderStyle: 'solid',
      borderWidth: 1,
      fontSize: 2,
      fontFamily: 'body',
    },
    login: {
      color: 'black',
      borderColor: '#e5e5e5',
      fontFamily: 'body',
    },
  },
  variants: {},
  text: {},
  buttons: {
    primary: {
      color: 'white',
      bg: 'brand',
      borderWidth: 0,
      borderRadius: 0,
      fontSize: 2,
      fontFamily: 'heading',
      textTransform: 'lowercase',
      ':hover': {
        cursor: 'pointer',
      },
      ':disabled': {
        opacity: 0.7,
      },
    },
    secondary: {
      color: 'white',
      backgroundColor: 'transparent',
      borderWidth: 1,
      borderStyle: 'solid',
      borderColor: 'white',
      borderRadius: 0,
      fontSize: 1,
      fontFamily: 'heading',
      textTransform: 'lowercase',
      ':hover': {
        cursor: 'pointer',
      },
      ':disabled': {
        opacity: 0.7,
      },
    },
    login: {
      color: 'white',
      bg: 'brand',
      borderWidth: 0,
      borderRadius: 40,
      fontSize: 2,
      px: 4,
      py: 2,
      fontWeight: 'bold',
      boxShadow: '0 0 20px 0 rgba(0, 0, 0, 0.16)',
      fontFamily: 'heading',
      textTransform: 'lowercase',
      ':hover': {
        cursor: 'pointer',
      },
      ':disabled': {
        opacity: 0.6,
      },
    },
    link: {
      padding: 0,
      backgroundColor: 'transparent',
      borderWidth: 0,
      fontFamily: 'body',
      ':hover': {
        cursor: 'pointer',
      },
    },
  },
};
