import React from 'react';
import * as yup from 'yup';
import { Box, Button, Flex } from 'theme-ui';
import { Label, Input } from 'theme-ui';
import { Auth } from '@aws-amplify/auth';
import { useRouter } from 'next/router';
import { useTranslation } from 'react-i18next';
import { Formik, Form, ErrorMessage, Field } from 'formik';

function LoginForm() {
  const { t } = useTranslation();
  const router = useRouter();

  const schema = yup.object().shape({
    email: yup.string().required().email(),
    password: yup.string().required().min(6),
  });

  return (
    <Box my={4}>
      <Formik
        initialValues={{ email: '', password: '' }}
        validationSchema={schema}
        onSubmit={(values, { setStatus }) => {
          Auth.signIn({
            username: values.email,
            password: values.password,
          })
            .then(() => router.push('/admin'))
            .catch((error) => setStatus(error.message));
        }}
      >
        {({ isSubmitting, status }) => (
          <Form>
            <Label htmlFor="email" variant="login" mt={3}>
              {t('common.email')}
            </Label>
            <Field
              as={Input}
              id="email"
              type="email"
              name="email"
              autoFocus /* eslint-disable-line jsx-a11y/no-autofocus */
              required
              autoComplete="email"
              variant="login"
            />
            <Box color="red">
              <ErrorMessage name="email" />
            </Box>

            <Label htmlFor="password" variant="login" mt={3}>
              {t('common.password')}
            </Label>
            <Field
              as={Input}
              id="password"
              type="password"
              name="password"
              required
              autoComplete="current-password"
              variant="login"
            />
            <Box color="red">
              <ErrorMessage name="password" />
            </Box>

            <Flex mt={4}>
              {status && (
                <Box color="red" mb={1}>
                  {status.message}
                </Box>
              )}
              <Button
                type="submit"
                variant="login"
                mx="auto"
                disabled={isSubmitting}
              >
                {t('common.signIn')}
              </Button>
            </Flex>
          </Form>
        )}
      </Formik>
    </Box>
  );
}

export default LoginForm;
