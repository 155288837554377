import React, { useState, useEffect } from 'react';
import { Flex } from 'theme-ui';
import Image from 'next/image';

import logo from 'images/logo.svg';
import background1 from '../images/background1.jpg';
import background2 from '../images/background2.jpg';
import background3 from '../images/background3.jpg';
import background4 from '../images/background4.jpg';
import background5 from '../images/background5.jpg';

const BACKGROUNDS = [
  background1,
  background2,
  background3,
  background4,
  background5,
];

function Login({ children }) {
  const [background, setBackground] = useState(null);
  useEffect(() => {
    const random = Math.floor(Math.random() * (BACKGROUNDS.length - 1));
    setBackground(BACKGROUNDS[random].src);
  }, []);

  return (
    <Flex
      sx={{
        backgroundImage: background ? `url(${background})` : null,
        backgroundSize: 'cover',
        backgroundColor: 'white',
        minHeight: '100vh',
        px: [3, 0],
      }}
    >
      <Flex
        sx={{
          boxShadow: 'large',
          flexDirection: 'column',
          alignItems: 'stretch',
          backgroundColor: 'white',
          width: 384,
          margin: 'auto',
        }}
      >
        <Flex
          sx={{
            backgroundColor: 'brand',
            p: 2,
            justifyContent: 'center',
            alignItems: 'center',
            height: 59,
          }}
        >
          <Image src={logo} alt="Fotokorner" width="105" height="24" />
        </Flex>

        <Flex
          sx={{
            p: 4,
            alignItems: 'stretch',
            flexDirection: 'column',
          }}
        >
          {children}
        </Flex>
      </Flex>
    </Flex>
  );
}

export default Login;
