import React from 'react';
import { useTranslation } from 'react-i18next';
import { Heading, Box } from 'theme-ui';

import LoginForm from 'components/loginform';
import CenteredBox from 'components/centeredbox';
import SetupLink from 'components/billing/setuplink';
import Layout from 'components/layout';

function Login() {
  const { t } = useTranslation();

  return (
    <Layout>
      <CenteredBox>
        <Heading
          sx={{
            mx: 'auto',
            fontSize: 4,
            color: 'black',
          }}
        >
          {t('common.signIn')}
        </Heading>

        <LoginForm />

        <Box mt={3} mx="auto">
          <SetupLink
            href="/register/"
            sx={{
              color: 'black',
              margin: 'auto',
            }}
          >
            {t('common.createAccount')}
          </SetupLink>
        </Box>
      </CenteredBox>
    </Layout>
  );
}

export default Login;
